<template>
  <div id="background">
    <el-card>
      <template #header>
        <div class="card-header" style="color: white">
          Welcome to Play Spider
        </div>
      </template>
      <el-form
        :model="loginReq"
        ref="loginForm"
        label-width="0px"
        :rules="rules"
        v-loading="loading"
        element-loading-background="rgb(0, 0, 0, 0)"
        element-loading-text="Loging in..."
      >
        <div>
          <el-row justify="center">
            <el-col :span="16">
              <el-form-item prop="account">
                <el-input
                  class="input"
                  type="text"
                  v-model="loginReq.account"
                  :prefix-icon="Avatar"
                  placeholder="账号"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="center" align="middle">
            <el-col :span="16">
              <el-form-item prop="password">
                <el-input
                  class="input"
                  v-model="loginReq.password"
                  type="password"
                  :prefix-icon="Lock"
                  placeholder="密码"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row justify="center">
            <el-col :span="13">
              <el-button @click="handleLogin">登陆</el-button>
            </el-col>
            <el-col :span="3">
              <el-button @click="handleLogin">注册</el-button>
            </el-col>
          </el-row> -->
          <el-row justify="center">
            <el-col :span="16">
              <el-button @click="handleLogin">登陆</el-button>
            </el-col>
          </el-row>
          <el-row justify="center" style="margin-top: 15px">
            <el-col :span="16">
              <el-button @click="handleRegister">注册</el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { setRole } from "../utils/permission";
import { login } from "../api/user";
import { setLoginInfo } from "../utils/auth";
import { getQueryString } from "../utils/url";
import { Avatar, Lock } from "@element-plus/icons-vue";

export default {
  name: "Login",
  data() {
    return {
      Avatar,
      Lock,
      loading: false,
      loginReq: {
        account: "",
        password: "",
      },
      rules: {
        account: [
          {
            required: true,
            message: "账号必填",
            trigger: "blur",
          },
          {
            max: 64,
            message: "长度不能超过64 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            max: 10,
            message: "长度不能超过10 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          login({
            account: this.loginReq.account,
            password: this.loginReq.password,
          })
            .then((response) => {
              setLoginInfo(response.data.data);
              setRole(response.data.data.id, response.data.data.role);
              this.loading = false;
              const redirectUrl = getQueryString("redirect");
              if (redirectUrl) {
                this.$router.push(redirectUrl);
              } else {
                this.$router.push({ name: "appList" });
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleRegister(){
      this.$router.push({
        name:"register",
      })
    },
  },
};
</script>

<style scoped>
.el-card {
  position: absolute;
  width: 480px;
  height: 230px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 40%;
  border: 0px solid black;
  padding: 50px;
  background-color: rgb(0, 0, 0, 0%);
}
.card-header {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: bold;
}
.input >>> .el-input__inner {
  border-radius: 20px;
  width: 300px;
  border: 0;
  background-color: rgb(0, 0, 0, 30%);
  color: white;
  /* background-color: #000000; */
}
.el-button {
  border-radius: 20px;
  width: 300px;
  color: white;
  background-color: rgb(0, 119, 237);
  border: 0;
}
html {
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
}
#background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../assets/login-background.jpg");
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>